<template>
  <MainLayout>
    <v-card class="ma-5">
      <v-card-title>
        <v-icon>mdi-office-building</v-icon> PLACES
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Active
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="place of places" :key="place.id">
              <td>{{place.id}}</td>
              <td>{{place.name}}</td>
              <td>
                <v-icon v-if="place.active" color="green">mdi-checkbox-marked-circle</v-icon>
                <v-icon v-if="!place.active" color="red">mdi-cancel</v-icon>
              </td>
              <td>
                <v-btn depressed color="secondary"
                       :to="{ name: 'trainsById', params: { placeId: place.id } }">
                  <v-icon class="mr-4">mdi-eye</v-icon> Show trains
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </MainLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MainLayout from '../../components/Layouts/MainLayout.vue';

export default {
  name: 'CamerasPage',
  components: { MainLayout },
  computed: {
    ...mapState('resources', ['places']),
  },

  created() {
    this.getPlacesFromApi();
  },

  methods: {
    ...mapActions('resources', ['getPlacesFromApi']),
  },
};
</script>

<style lang="scss" scoped>

</style>
